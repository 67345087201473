import { httpGetAsync, httpPostAsync} from "./requestService";

export const getAsync = async (page, perPage, projectId = '', customerId = '', dateStart = '', dateEnd = '', isPrivate = 0) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/revenue?page=${page}&per_page=${perPage}&project_id=${projectId}&customer_id=${customerId}&start_at=${dateStart}&end_at=${dateEnd}&is_private=${isPrivate}&is_show_for_tax=1`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const getTodayPriceAsync = async () => {
    const currentDate = getCurrentDateString();

    const url = `${process.env.VUE_APP_BACKEND_API_URL}/revenue?page=1&per_page=1000&start_at=${currentDate}&end_at=${currentDate}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const findAsync = async (id) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/revenue/${id}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const createAsync = async (revenueObj) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/revenue/create`;
    const postData = JSON.stringify(revenueObj);
    const resp = await httpPostAsync(url, postData);

    return resp.data;
}

export const updateAsync = async (id, revenueObj) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/revenue/edit/${id}`;
    const postData = JSON.stringify(revenueObj);
    const resp = await httpPostAsync(url, postData);

    return resp.data;
}

export const removeAsync = async (id) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/revenue/delete/${id}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const bankCheckAsync = async (ids) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/revenue/bank-check?ids=${ids}`;
    const resp = await httpGetAsync(url);
    
    return resp;
}

const getCurrentDateString = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero based
    const day = String(today.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
}