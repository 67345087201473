<template>
    <div>
        <div class="title-wrapper">
            <h1>Dự án ({{ total }})</h1>
            <div><router-link class="btn btn-success" :to="{name: 'projects.create'}"><i class="fas fa-plus"></i> Thêm dự án</router-link></div>
        </div>
        
        <!-- Filter -->
        <form method="get" @submit.prevent="filterAsync">
            <div class="filter-form">
                <div class="row">
                    <div class="col-md-3">
                        <input v-model="filter.search" type="text" placeholder="Tìm kiếm tên dự án..." class="form-control">
                        <div class="b5"></div>
                    </div>
                    <div class="col-md-3">
                        <select v-model="filter.order" class="form-control" @change="filterAsync">
                            <option value="sale-desc">Tổng license (cao > thấp)</option>
                            <option value="sale-asc">Tổng licenses (thấp > cao)</option>
                            <option value="created-desc">Mới > cũ</option>
                            <option value="created-asc">Cũ > mới</option>
                        </select>
                        <div class="b5"></div>
                    </div>
                    <div class="col-md-3">
                        <button type="button" class="btn btn-light btn-reset-filter" @click="resetFilterAsync">Đặt lại bộ lọc</button>
                        <div class="b5"></div>
                    </div>
                </div>
            </div>
        </form>

        <!-- Data table -->
        <div class="data-table">
            <table class="data-table--vertical-top">
                <colgroup>
                    <col width="30%">
                    <col width="30%">
                </colgroup>
                <thead>
                    <tr>
                        <th>Tên dự án</th>
                        <th>Trạng thái</th>
                        <th>Ngày tạo</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="loading-row" v-show="inLoading">
                        <td colspan="5">
                            <div class="loading-spinner"></div>
                        </td>  
                    </tr>
                    <tr v-for="item in data" v-bind:key="item.id" v-show="!inLoading">
                        <td>
                            <span>{{ item.name }}</span>
                            <br/>
                            <span style="color: #757575"><i class="fas fa-code-branch"></i> : {{ item.version }}</span>
                            <br/><br/>
                            <router-link class="makeUpdateBtn" :to="{name: 'projects.push-update', params: {id: item.id}}">
                                Tạo cập nhật
                            </router-link>
                        </td>
                        <td>
                            <span v-if="item.is_active" class="badge bg-success">Đang hoạt động</span>
                            <span v-else class="badge bg-danger">Ngừng hoạt động</span>
                        </td>
                        <td>
                            {{ formatDateStr(item.created_at) }}
                        </td>
                        <td>
                            <router-link :to="{name: 'projects.edit', params: {id: item.id}}" class="btn-edit" title="Sửa"><i
                                    class="far fa-edit"></i></router-link>
                            <a @click="removeAsync(item.id)" class="btn-del" title="Xóa"><i class="fas fa-trash-alt"></i></a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        

        <nav>
            <ul class="pagination">
                <li class="page-item"><a class="page-link" @click="prevPageAsync()"><i class="fas fa-angle-left"></i></a></li>
                <li class="page-item" :class="{'active': (page == this.filter.page)}" v-for="page in paginationPageList" v-bind:key="page">
                    <a class="page-link" @click="goToPageAsync(page)">{{page}}</a>
                </li>
                <li class="page-item"><a class="page-link" @click="nextPageAsync()"><i class="fas fa-angle-right"></i></a></li>
            </ul>
        </nav>
    </div>
</template>
  
<script>
import * as db from '@/services/projectService'
import NProgress from 'nprogress';

import { getItemIndex } from '@/helpers/arrayHelper';
import { calcPaginationPageList } from '@/helpers/paginationHelper';
import { formatDateString } from '@/helpers/strHelper';

export default {
    name: 'ProjectPage',
    created() {
        document.title = 'Dự án';

        this.filter = this.$route.query;

        // Default
        if (!this.filter.perPage) this.filter.perPage = 10;
        if (!this.filter.page) this.filter.page = 1;
        if (!this.filter.search) this.filter.search = '';
        if (!this.filter.order) this.filter.order = 'sale-desc';
    },
    // Get data
    async mounted() {
        await this.loadAsync(false);
    },
    data() {
        return {
            data: [],
            filter: {
                page: 1,
                perPage: 10,
                search: '',
                order: 'sale-desc'
            },
            total: 0,
            totalPage: 0,
            paginationPageList: [],
            inLoading: true
        }
    },
    methods: {
        async removeAsync(id) {
            const item = getItemIndex(this.data, 'id', id);

            if (confirm('Bạn có muốn xóa vĩnh viễn dự án: ' + item.item.name)) {
                NProgress.start();
                const result = await db.removeAsync(id);
                if (result.success == true) {
                    this.data.splice(item.index, 1);
                    this.total--;
                    this.$toast.success('Xóa dự án thành công');
                } else {
                    this.$toast.error(result.message);
                }
                NProgress.done();
            }
        },

        async filterAsync(){
            this.filter.page = 1;
            await this.loadAsync();
        },

        async resetFilterAsync() {
            this.filter.page = 1;
            this.filter.search = '';
            this.filter.order = 'sale-desc';
            await this.loadAsync();
        },

        async loadAsync(scrollToTop = true) {
            this.inLoading = true;
            if (scrollToTop)
                window.scrollTo({ top: 0, behavior: 'smooth' });

            let orderBy = '', orderByType = '';
            if (this.filter.order == 'sale-desc') {
                orderBy = 'licenses_count';
                orderByType = 'desc';
            } else if (this.filter.order == 'sale-asc') {
                orderBy = 'licenses_count';
                orderByType = 'asc';
            } else if (this.filter.order == 'created-desc') {
                orderBy = 'created_at';
                orderByType = 'desc';
            } else if (this.filter.order == 'created-asc') {
                orderBy = 'created_at';
                orderByType = 'asc';
            }


            const result = await db.getAsync(this.filter.page, this.filter.perPage, this.filter.search, orderBy, orderByType);
            if (result.success == true)
            {
                this.data = result.data.data;
                this.total = result.data.total;
                this.totalPage = result.data.last_page;
                this.paginationPageList = calcPaginationPageList(this.filter.page, this.totalPage);
            }

            this.inLoading = false;
        },

        async showDeviceCountAsync(id, event){
            const count = (await db.countActiveDevicesAsync(id)).data;
            const span = event.target;
            span.innerText = count;
        },

        // Pagination --------------------------------------------------------------------------------
        async nextPageAsync(){
            this.filter.page++;
            await this.loadAsync();
        },

        async prevPageAsync(){
            if (this.filter.page <= 1)
                return;

            this.filter.page--;
            await this.loadAsync();
        },

        async goToPageAsync(page){
            this.filter.page = page;
            await this.loadAsync();
        },

        // Helper ---------------------------------------------------------------------------------------
        formatDateStr(dateStr){
            return formatDateString(dateStr);
        }
    }
    
}
</script>

<style>
.makeUpdateBtn {
    border: 1px solid #00A88E;
    padding: 3px 8px;
    font-size: 12px;
    border-radius: 4px;
    color: #00A88E;
}

.makeUpdateBtn:hover {
    color: #00c8aa;
    border: 1px solid #00c8aa;
}
</style>