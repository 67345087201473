import { httpGetAsync, httpPostAsync, TOKEN_NAME} from "./requestService";
import store from '@/store';

// Login account
// Return a object like: {success: true, message: "Thành công"}
export const loginAsync = async (email, password, loginToken = '') => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/auth/system-login?email=${email}&password=${password}&login_token=${loginToken}`;
    const resp = await httpPostAsync(url);

    if (resp.data.success == true) {
        localStorage.setItem(TOKEN_NAME, resp.data.data);
    }
    
    return {
        success: resp.data.success,
        message: resp.data.message
    };
}

export const verifyTokenAsync = async (routeName) =>
{
    const token = localStorage.getItem(TOKEN_NAME);

    if (token) {
        const authCache = localStorage.getItem('AUTH_CACHE');
        if (authCache){
            const authCacheObj = JSON.parse(authCache);
            store.commit('setUserAccessRoutes', authCacheObj.data.data.access_routes);
            store.commit('setSystemRole', authCacheObj.data.data.system_role);
            store.commit('setCurrentUserDisplayName', authCacheObj.data.data.user.display_name);

            return authCacheObj;
        }

        // New request
        const url = `${process.env.VUE_APP_BACKEND_API_URL}/auth/check-token?route_name=frontend.${routeName}`;
        const resp = await httpGetAsync(url);

        // Set access_routes to store
        if (resp.data.success == true){
            store.commit('setUserAccessRoutes', resp.data.data.data.access_routes);
            store.commit('setSystemRole', resp.data.data.data.system_role);
            store.commit('setCurrentUserDisplayName', resp.data.data.data.user.display_name);

            // Save to cache
            localStorage.setItem('AUTH_CACHE', JSON.stringify(resp.data));
        }

        return resp.data;
    }
    
    return {success: false};
}

export const getCurrentUserAsync = async () => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/auth/get-current-user`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const updateCurrentUserAsync = async (userObj) => {
    const postData = JSON.stringify(userObj);
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/auth/update-current-user`;
    const resp = await httpPostAsync(url, postData);

    return resp.data;
}

export const logoutAsync = async () => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/auth/logout`;
    await httpGetAsync(url);
    localStorage.removeItem(TOKEN_NAME);
    localStorage.removeItem('AUTH_CACHE');
}