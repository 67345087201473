<template>
  <div class="left-menu">
    <div class="left-menu__logo">
      <h1>
        <i class="fas fa-shield-alt"></i>
        <span class="left-menu__label admin-label">ADMIN SITE</span>
      </h1>
    </div>
    <ul>
      <li class="left-menu__item" :class="{selected: this.$route.path.includes('/licenses'), deactive: !canAccessMenu('licenses')}">
        <router-link :to="{name: 'licenses'}"> 
          <i class="fab fa-instalod"></i>
          <span class="left-menu__label">Quản lý License</span>
        </router-link>
      </li>
      <li class="left-menu__item" :class="{selected: this.$route.path.includes('/projects'), deactive: !canAccessMenu('projects')}">
        <router-link :to="{name: 'projects'}">
          <i class="fas fa-desktop"></i>
          <span class="left-menu__label">Dự án</span>
        </router-link>
      </li>
      <li class="left-menu__item" :class="{selected: this.$route.path.includes('/data-report'), deactive: !canAccessMenu('data-report')}">
        <router-link :to="{name: 'data-report'}">
          <i class="far fa-calendar-minus"></i>
          <span class="left-menu__label">Data report</span>
        </router-link>
      </li>
      <li class="left-menu__hr"></li>
      <li class="left-menu__item" :class="{selected: this.$route.path.includes('/revenue'), deactive: !canAccessMenu('revenue')}">
        <router-link :to="{name: 'revenue'}">
          <i class="fas fa-chart-bar"></i>
          <span class="left-menu__label">Doanh thu</span>
        </router-link>
      </li>
      <li class="left-menu__item" :class="{selected: this.$route.path.includes('/orders'), deactive: !canAccessMenu('orders')}">
        <router-link :to="{name: 'orders'}">
          <i class="fas fa-shopping-bag"></i>
          <span class="left-menu__label">Đơn hàng</span>
        </router-link>
      </li>
      <li class="left-menu__hr"></li>
      <li class="left-menu__item" :class="{selected: this.$route.path.includes('/users'), deactive: !canAccessMenu('users')}">
        <router-link :to="{name: 'users'}">
          <i class="far fa-user"></i>
          <span class="left-menu__label">Người dùng</span>
          </router-link>
      </li>
      <li class="left-menu__item" :class="{selected: this.$route.path.includes('/tools'), deactive: !canAccessMenu('tools')}">
        <router-link :to="{name: 'tools'}">
          <i class="fas fa-tools"></i>
          <span class="left-menu__label">Công cụ</span>
        </router-link>
      </li>
      <li class="left-menu__hr"></li>
      <li class="left-menu__item" :class="{selected: this.$route.path.includes('/api'), deactive: !canAccessMenu('api')}">
        <a href="#" @click="openApiDocUrl">
          <i class="far fa-file-code"></i>
          <span class="left-menu__label">Tài liệu API</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'LeftMenu',
  created() {
    this.$store.getters.getFrontEndUserRoutes.forEach(item => {
      const rawRouteName = item.route_name.replace('frontend.', '');
      this.accessRouteNames.push(rawRouteName);
      console.log(rawRouteName);
    });
  },
  data() {
    return {
      accessRouteNames: []
    }
  },
  methods: {
    canAccessMenu(routeName) {
      if (this.$store.getters.getSystemRole == 'ADMIN')
        return true;

      return this.accessRouteNames.includes(routeName);
    },
    openApiDocUrl(){
      const url = `${process.env.VUE_APP_BACKEND_URL}/api/back-end-doc`;
      window.open(url, '_blank');
    }
  }
}
</script>

<style scoped>
.left-menu {
  position: fixed;
  height: 100%;
  width: 60px;
  top: 0;
  left: -15px;
  background: #202d3f;
  align-items: stretch;
  z-index: 99999;
  overflow: hidden;
}

.left-menu:hover {
  width: 240px !important;
  left: 0px;
  transition: all 0.2s;
}

.left-menu:hover .left-menu__label {
  position: absolute;
  left: 30px;
  height: 23px;
  overflow: hidden;
  display: inline-block;
  top: 0px;
}
.left-menu:hover .admin-label {
  position: absolute;
  left: 60px;
  height: 23px;
  overflow: hidden;
  display: inline-block;
  top: 17px;
}

.left-menu ul {
  list-style-type: none;
  padding: 0px;
}

.left-menu__logo {
  padding: 15px 30px 10px 30px;
  color: white;
  height: 55px;
}

.left-menu__logo h1 {
  font-size: 18px;
  font-weight: bold;
  color: white;
}

.left-menu__logo h1 span {
  font-size: 18px;
  font-weight: bold;
  color: white;
}

.left-menu ul li {
  display: block;
  padding: 10px 30px 10px 30px;
}
.left-menu__item {
  height: 41px;
}

.left-menu .selected {
  background: #2a3a51;
}

.left-menu__item:hover {
  background: #3d5576;
}

.left-menu ul li a {
  color: #eaecec;
  text-decoration: none;
  font-size: 14px;
  display: block;
  position: relative;
}

.deactive a {
  color: #a3a3a3 !important;
  cursor: not-allowed;
  pointer-events: none;
}

.deactive a i {
  color: #a3a3a3 !important;
}

.left-menu__label {
  display: none;
}

.left-menu__hr {
  color: #414141;
  background: #414141;
  width: 100%;
  height: 1px;
  padding: 0px !important;
}

i {
  color: white !important;
  display: inline-block;
  width: 25px;
}

.hide {
  display: none;
}
</style>