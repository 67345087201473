import { httpGetAsync, httpPostAsync} from "./requestService";

// Auth support ----------------------------------------------------------------------------
export const getCurrentUserAsync = async () => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/auth/get-current-user`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const changePasswordAsync = async (password) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/auth/new-password?password=${password}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}


// CRUD -------------------------------------------------------------------------------------
export const getCustomersAsync = async (page, perPage, search = '', orderBy = 'id', orderByType = 'asc', sys_role = 'CUSTOMER', selectCols = '') => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/users?page=${page}&per_page=${perPage}&search=${search}&order_by=${orderBy}&order_by_type=${orderByType}&system_role=${sys_role}&select_columns=${selectCols}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const findAsync = async (id) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/users/${id}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}


export const createAsync = async (userObj) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/users/create`;
    const postData = JSON.stringify(userObj);
    const resp = await httpPostAsync(url, postData);

    return resp.data;
}

export const updateAsync = async (id, userObj) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/users/edit/${id}`;
    const postData = JSON.stringify(userObj);
    const resp = await httpPostAsync(url, postData);

    return resp.data;
}

export const removeAsync = async (id) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/users/delete/${id}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const setActiveAffiliateAsync = async(id) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/users/active-affiliate/${id}`;
    await httpGetAsync(url);
}

// Async customer from old db
export const convertUserFromOldSystem = async (page) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/users/update-from-old-system?page=`+page;
    const resp = await httpGetAsync(url);

    return resp.data;
}