import * as VueRouter from 'vue-router';
import NProgress from 'nprogress';

import LoginPage from '../pages/AuthPages/LoginPage.vue';
import MyAccountPage from '../pages/AuthPages/MyAccount.vue';
import NotFound from '../pages/NotFound.vue';
import CanNotAccess from '../pages/CanNotAccess.vue';
import HomePage from '../pages/Admin/Home/index.vue';

// Layout
import AuthLayout from '../layouts/AuthLayout.vue';
import AdminLayout from '../layouts/AdminLayout.vue';

import { verifyTokenAsync } from '../services/authService';


const routes = [
  {
    name: 'home-page',
    path: '/',
    component: HomePage,
    meta:
    {
      layout: AdminLayout,
      requiresAuth: true
    },
  },
  {
    name: 'login',
    path: '/login',
    component: LoginPage,
    meta:
    {
      layout: AuthLayout,
      requiresAuth: false
    },
  },
  {
    name: 'my-account',
    path: '/my-account',
    component: MyAccountPage,
    meta:
    {
      layout: AdminLayout,
      requiresAuth: false
    },
  },
  {
    name: 'not-found',
    path: '/not-found',
    component: NotFound,
    meta:
    {
      layout: AuthLayout,
      requiresAuth: false
    },
  },
  {
    name: '401',
    path: '/401',
    component: CanNotAccess,
    meta:
    {
      layout: AuthLayout,
      requiresAuth: false
    }
  }
];

// Dynamic route for admin folder

// Use require.context to dynamically import files from the "pages/Admin" folder
const pagesContext = require.context('../pages/Admin', true, /\.vue$/);

pagesContext.keys().forEach((fileName) => {
  // Get the component configuration
  const componentConfig = pagesContext(fileName);
  
  // fileName : ./Licenses/create.vue
  let routePath = fileName.replace('./', '').replace('.vue', '').replace('_', '-').toLowerCase(); // --> licenes/create
  if (routePath.includes('/index')) routePath = routePath.replace('/index', '');
  else if (!routePath.includes('create')) routePath += '/:id';


  let componentName = fileName.replace('./', '').replace('/', '.').replace('.vue', '').replace('_', '-').toLowerCase(); // --> licenes/create
  componentName = componentName.replace('/', '-'); // --> licenes-create
  componentName = componentName.replace('.index', '');

  // Create a route object and add it to the routes array
  const route = {
    name: componentName,
    path: '/'+routePath, // Generate a path based on the component name
    component: componentConfig.default || componentConfig,
    meta:
    {
      layout: AdminLayout,
      requiresAuth: true
    }
  };

  routes.push(route);
});

// console.log(routes);

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes,
});

// Handle not found url, middle, loading page effect
router.beforeEach(async (to, from, next) => {
  if (to.name)
    NProgress.start();

  if (to.matched.length == 0) {
    next('not-found');
    return;
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    const verifyTokenResult = await verifyTokenAsync(to.name);

    if (verifyTokenResult.success == true) {
      if (verifyTokenResult.data.success == true || to.name.includes('home'))
        next();
      else
        next('/401');
    } else {
      next('/login');
    }
  } else {
    next();
  }
});


router.afterEach(() => {
  window.scrollTo({ top: 0, behavior: 'instant' });
  NProgress.done();
});

export default router;
