import { httpGetAsync, httpPostAsync} from "./requestService";

export const getAsync = async (page, perPage, transferContent = '', isTransfered = '', customerId = '') => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/orders?page=${page}&per_page=${perPage}&transfer_content=${transferContent}&is_transfered=${isTransfered}&customer_id=${customerId}&is_show_for_tax=1`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const findAsync = async (id) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/orders/${id}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const createAsync = async (orderObj) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/orders/create`;
    const postData = JSON.stringify(orderObj);
    const resp = await httpPostAsync(url, postData);

    return resp.data;
}

export const updateAsync = async (id, orderObj) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/orders/edit/${id}`;
    const postData = JSON.stringify(orderObj);
    const resp = await httpPostAsync(url, postData);

    return resp.data;
}

export const updateTransferedStatusAsync = async (id) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/orders/edit/${id}`;
    const postData = JSON.stringify({is_transfered: true});
    const resp = await httpPostAsync(url, postData);

    return resp.data;
}

export const removeAsync = async (id) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/orders/delete/${id}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const getRandomTransferContentAsync = async () => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/orders/get-random-transfer-content`;
    const resp = await httpGetAsync(url);

    if (resp.data.success == true)
        return resp.data.data;
    
    return 'UNKNOW_ERROR';
}

export const getAffiliateInfoAsync = async (affCode) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/customer/get-affiliate-info/${affCode}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}