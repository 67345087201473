<template>
    <div>
        <div class="title-wrapper">
            <h1>Người dùng ({{ total }})</h1>
            <div>
                <router-link class="btn btn-success" :to="{name: 'users.create'}"><i class="fas fa-plus"></i> Thêm người dùng</router-link>
            </div>
        </div>
        
        <form @submit.prevent="filterFormAsync">
            <div class="filter-form">
                <div class="row">
                    <div class="col-md-2">
                        <input v-model="filter.search" type="text" placeholder="Tìm kiếm..." class="form-control">
                        <div class="b5"></div>
                    </div>
                    <div class="col-md-2">
                        <select class="form-control" v-model="filter.sortString" @change="filterFormAsync">
                            <option value="VIP">Người dùng VIP</option>
                            <option value="NEWEST">Người dùng mới</option>
                            <option value="NAME">Theo tên A-Z</option>
                            <option value="AFF_POINT">Affliate</option>
                        </select>

                        <div class="b5"></div>
                    </div>
                    <div class="col-md-2">
                        <select class="form-control" v-model="filter.role" @change="filterFormAsync">
                            <option value="ALL">Tất cả</option>
                            <option value="CUSTOMER">Khách hàng</option>
                            <option value="EMPLOYEE">Nhân viên</option>
                            <option value="ADMIN">Quản trị viên</option>
                        </select>

                        <div class="b5"></div>
                    </div>
                    <div class="col-md-2">
                        <button type="button" class="btn btn-light btn-reset-filter" @click="resetFilterAsync">Đặt lại bộ lọc</button>
                        <div class="b5"></div>
                    </div>
                </div>
            </div>
        </form>

        <div class="data-table">
            <table>
                <colgroup>
                    <col width="25%">
                    <col width="25%">
                </colgroup>
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Thông tin</th>
                        <th>Trạng thái</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="loading-row" v-show="inLoading">
                        <td colspan="5">
                            <div class="loading-spinner"></div>
                        </td>  
                    </tr>
                    <tr class="no-data-row" v-if="data.length == 0 && !inLoading">
                        <td colspan="6">Không có dữ liệu để hiển thị</td>
                    </tr>
                    <tr v-for="item in data" v-bind:key="item.id" v-show="!inLoading">
                        <td>
                            <div class="user-main-info">
                                <div class="user-main-info__avatar">
                                    <i v-if="!item.avatar" class="fas fa-user"></i>
                                    <img v-else :src="backEndUrl+item.avatar">
                                </div>
                                <div style="flex: 1;">
                                    <b>{{ item.email }}</b>
                                    <br/>
                                    <span class="sys-span" :class="'sys-'+item.system_role">{{ item.system_role }}</span>
                                </div>
                            </div>
                        </td>
                        <td>
                            <span>
                                <b>{{ item.display_name }}</b>
                            </span>
                        </td>
                        <td class="status-td">
                            <span v-if="item.is_active" class="badge-green"></span>
                            <span v-else class="badge-red"></span>
                        </td>
                        <td>
                            <router-link :to="{name: 'users.edit', params: {id: item.id}}" class="btn-edit" title="Edit"><i
                                    class="far fa-edit"></i></router-link>
                            <a @click="removeAsync(item.id)" class="btn-del" title="Delete"><i class="fas fa-trash-alt"></i></a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        

        <nav>
            <ul class="pagination">
                <li class="page-item"><a class="page-link" @click="prevPageAsync()"><i class="fas fa-angle-left"></i></a></li>
                <li class="page-item" :class="{'active': (page == this.filter.page)}" v-for="page in paginationPageList" v-bind:key="page">
                    <a class="page-link" @click="goToPageAsync(page)">{{page}}</a>
                </li>
                <li class="page-item"><a class="page-link" @click="nextPageAsync()"><i class="fas fa-angle-right"></i></a></li>
            </ul>
        </nav>
    </div>
</template>
  
<script>
import * as db from '@/services/userService';
import { getItemIndex } from '@/helpers/arrayHelper';
import { formatDateString } from '@/helpers/strHelper';
import { calcPaginationPageList } from '@/helpers/paginationHelper';

import NProgress from 'nprogress';

export default {
    name: 'UserPage',
    created() {
        document.title = 'User manager';
        this.filter = this.$route.query;

        // Default
        if (!this.filter.perPage) this.filter.perPage = 15;
        if (!this.filter.page) this.filter.page = 1;
        if (!this.filter.sortString) this.filter.sortString = 'VIP';
        if (!this.filter.role) this.filter.role = 'ALL';
    },
    // Get data
    async mounted() {
        await this.loadAsync(false);
    },
    data() {
        return {    
            data: [],
            filter: {
                page: 1,
                perPage: 15,
                search: '',
                sortString: 'VIP',
                role: 'ALL'
            },
            total: 0,
            totalPage: 0,
            paginationPageList: [],
            backEndUrl: process.env.VUE_APP_BACKEND_URL,
            inLoading: true
        }
    },
    methods: {
        async removeAsync(id) {
            const item = getItemIndex(this.data, 'id', id);

            if (confirm('Bạn có chắc chắn muốn xóa vĩnh viễn người dùng : ' + item.item.email)) {
                NProgress.start();
                const result = await db.removeAsync(id);
                if (result.success == true) {
                    this.data.splice(item.index, 1);
                    this.$toast.success('Xóa người dùng thành công');
                } else {
                    this.$toast.error(result.message);
                }
                NProgress.done();
            }
        },

        async filterFormAsync(){
            this.filter.page = 1;
            await this.loadAsync();
        },

        async loadAsync(scrollToTop = true) {
            this.inLoading = true;
            if (scrollToTop)
                window.scrollTo({ top: 0, behavior: 'smooth' });

            let orderBy = 'licenses_count', orderType = 'desc';
            if (this.filter.sortString == 'VIP') {
                orderBy = 'licenses_count'; 
                orderType = 'desc';
            } else  if (this.filter.sortString == 'NEWEST') {
                orderBy = 'id'; 
                orderType = 'desc';
            } else  if (this.filter.sortString == 'NAME') {
                orderBy = 'display_name';
                orderType = 'asc';
            } else if (this.filter.sortString == 'AFF_POINT') {
                orderBy = 'affiliate_point';
                orderType = 'desc';
            }

            const result = await db.getCustomersAsync(this.filter.page, this.filter.perPage, this.filter.search, orderBy, orderType, this.filter.role);

            if (result.success == true)
            {
                this.data = result.data.data;
                this.total = result.data.total;
                this.totalPage = result.data.last_page;
                this.paginationPageList = calcPaginationPageList(this.filter.page, this.totalPage);
            }

            this.inLoading = false;
        },

        async setActiveAffiliateAsync(user){
            if (confirm('Xác nhận kích hoạt affiliate cho tài khoản '+ user.email + '?')){
                await db.setActiveAffiliateAsync(user.id);
                user.affiliate_verified = 1;
            }
        },
        
        // Filter ----------------------------------------------------------------------------------
        async resetFilterAsync(){
            this.filter.search = '';
            this.filter.sortString = 'VIP';
            this.filter.role = 'ALL';
            this.filter.page = 1;
  
            await this.loadAsync();
        },

        // Pagination --------------------------------------------------------------------------------
        async nextPageAsync(){
            if (this.filter.page == this.totalPage)
                return;

            this.filter.page++;
            await this.loadAsync();
        },

        async prevPageAsync(){
            if (this.filter.page <= 1)
                return;

            this.filter.page--;
            await this.loadAsync();
        },

        async goToPageAsync(page){
            this.filter.page = page;
            await this.loadAsync();
        },

        // Helpers -------------------------------------------------------------------------------------
        formatDateStr(dateString){
            return formatDateString(dateString);
        }
    }
    
}
</script>
  
<style scoped>

.sys-span {
    text-transform: lowercase;
    border-radius: 4px;
    padding: 3px 10px 3px 10px;
    font-size: 11px;
    font-weight: bold;
    margin-right: 10px;
}

.sys-ADMIN {
    color: #ff2626;
    border: 1px solid #ff2626;
    background: #ffcece;
}

.sys-EMPLOYEE {
    color: #009525;
    border: 1px solid #009525;
    background: #b5ffc8;
}

.sys-CUSTOMER {
    color: #18A0F7;
    border: 1px solid #18A0F7;
    background: #E7F3FF;
}

.user-main-info {
    display: flex;
}

.user-main-info__avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #d3d3d3;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.user-main-info__avatar i {
    font-size: 28px;
    text-align: center; 
    margin-top: 8px;
    color: white;
}

.user-main-info__avatar img {
    width: 100%;
}

.badge-green {
    display: block;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background-color: #00b92f;
    margin-left: 10px;
}

.badge-red {
    display: block;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background-color: #df0000;
    margin-left: 10px;
}

.status-td {
    vertical-align:middle !important;
}

b {
    color: #5b5b5b;
}
</style>