<template>
    <div>
        <div class="title-wrapper">
            <h1>Doanh thu ({{ total }})</h1>
            <div>
                <button class="btn btn-warning" style="margin: 0px 5px 0px 0px;" @click="bankCheckAsync">Đối soát tự động</button>
                <router-link class="btn btn-success" :to="{name: 'revenue.create'}"><i class="fas fa-plus"></i> Thêm doanh thu</router-link>
            </div>
        </div>
        
        <!-- Filter -->
        <form>
            <div class="filter-form">
                <div class="row">
                    <div class="col-md-3">
                        <SelectBox ref="selectProject" placeHolder="-- Chọn dự án" @onchange="updateProjectIdFilterAsync"></SelectBox>
                        <div class="b5"></div>
                    </div>
                    <div class="col-md-3">
                        <UserSearchBox ref="selectCustomer" @onchange="updateCustomerIdFilterAsync"></UserSearchBox>
                        <div class="b5"></div>
                    </div>
                    <div class="col-md-2">
                        <input v-model="filter.startDate" type="date" class="form-control" @input="filterByDateAsync">
                        <div class="b5"></div>
                    </div>
                    <div class="col-md-2">
                        <input v-model="filter.endDate" type="date" class="form-control" @input="filterByDateAsync">
                        <div class="b5"></div>
                    </div>
                    <div class="col-md-1">
                        <select class="form-control" v-model="filter.is_private" @change="filterByTypeAsync">
                            <option value="">All</option>
                            <option value="0">Public</option>
                            <option value="1">Private</option>
                        </select>
                        <div class="b5"></div>
                    </div>
                    <div class="col-md-1">
                        <button type="button" class="btn btn-light btn-reset-filter" @click="resetFilterAsync">Đặt lại</button>
                        <div class="b5"></div>
                    </div>
                </div>
            </div>
        </form>

        <div class="total">
            Tổng theo bộ lọc: {{ formatVNPrice(totalPrice) }}
        </div>

        <!-- Data table -->
        <div class="data-table">
            <table>
                <colgroup>
                    <col width="25%">
                    <col width="20%">
                </colgroup>
                <thead>
                    <tr>
                        <th>Số tiền</th>
                        <th>Nguồn</th>
                        <th>Nội dung</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="loading-row" v-show="inLoading">
                        <td colspan="5">
                            <div class="loading-spinner"></div>
                        </td>  
                    </tr>
                    <tr class="no-data-row" v-if="data.length == 0 && !inLoading">
                        <td colspan="6">Không có dữ liệu để hiển thị</td>
                    </tr>
                    <tr v-for="item in data" v-bind:key="item.id" v-show="!inLoading">
                        <td>
                            <b>{{ formatVNPrice(item.price) }}</b>
                            <span v-if="item.is_bank_checked" class="badge bg-success">Đã đối soát</span>
                            <br>
                            <small>{{ formatDateStr(item.created_at) }}</small>
                        </td>
                        <td>
                            <b>{{ item.customer.display_name }}</b><br>
                            <small>Email: {{item.customer.email}}</small><br>
                            <small>Địa chỉ: {{ item.customer.address }}</small><br>
                            <small>Số điện thoại: {{ item.customer.phone_number }}</small><br>
                            <small>MST: {{ item.customer.tax_code }}</small><br>
                            
                            <small>{{ item.project.name }}</small><br>
                            
                        </td>
                        <td>
                            <span>{{ item.content }}</span>
                            <small><br>{{ item.note }}</small>
                        </td>
                        <td>
                            <button class="btn btn-warning" style="margin: 0 5px 0 0;" :disabled="item.is_bank_checked == 1" @click="setBankCheckedStatusAsync(item)"><i class="fas fa-check"></i> Đã đối soát</button>
                            <router-link :to="{name: 'revenue.edit', params: {id: item.id}}" class="btn-edit" title="Sửa"><i
                                class="far fa-edit"></i></router-link>
                            <a @click="removeAsync(item.id)" class="btn-del" title="Xóa"><i class="fas fa-trash-alt"></i></a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <nav>
            <ul class="pagination">
                <li class="page-item"><a class="page-link" @click="prevPageAsync()"><i class="fas fa-angle-left"></i></a></li>
                <li class="page-item" :class="{'active': (page == this.filter.page)}" v-for="page in paginationPageList" v-bind:key="page">
                    <a class="page-link" @click="goToPageAsync(page)">{{page}}</a>
                </li>
                <li class="page-item"><a class="page-link" @click="nextPageAsync()"><i class="fas fa-angle-right"></i></a></li>
            </ul>
        </nav>
    </div>
</template>
  
<script>
import * as db from '@/services/revenueService';
import { getItemIndex } from '@/helpers/arrayHelper';
import {getAsync as getProjectsAsync} from '@/services/projectService';

import { formatDateTimeString, formatPrice } from '@/helpers/strHelper';
import { calcPaginationPageList } from '@/helpers/paginationHelper';

import NProgress from 'nprogress';
import UserSearchBox from '@/components/UserSearchBox.vue';
import SelectBox from '@/components/SelectBox.vue';

export default {
    name: 'RevenuePage',
    components: {
        UserSearchBox,
        SelectBox
    },
    created() {
        document.title = 'Doanh thu';
        this.filter = this.$route.query;

        // Default
        if (!this.filter.perPage) this.filter.perPage = 15;
        if (!this.filter.page) this.filter.page = 1;
        if (!this.filter.is_private) this.filter.is_private = '0';
    },
    // Get data
    async mounted() {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth()+1;
        const lastDay = this.getLastdayOfCurrentMonth();
        this.filter.startDate = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-01`;
        this.filter.endDate = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;

        await this.loadProjectSelectBoxAsync();
        await this.loadAsync(false);

        const todayData = await db.getTodayPriceAsync();
        console.log(todayData);
        this.todayPrice = todayData.data.total_price;
    },
    data() {
        return {
            data: [],
            totalPrice: 0,
            todayPrice: 0,
            filter: {
                page: 1,
                perPage: 15,
                project_id: null,
                customer_id: null,
                startDate: '',
                endDate: '',
                is_private: '0'
            },
            total: 0,
            totalPage: 0,
            paginationPageList: [],
            inLoading: true
        }
    },
    methods: {
        async removeAsync(id) {
            const item = getItemIndex(this.data, 'id', id);

            if (confirm('Bạn có chắc chắn muốn xóa nguồn doanh thu này')) {
                NProgress.start();
                const result = await db.removeAsync(id);
                if (result.success == true) {
                    this.data.splice(item.index, 1);
                    this.total--;
                    this.$toast.success('Xóa doanh thu thành công');
                } else {
                    this.$toast.error(result.message);
                }
                NProgress.done();
            }
        },

        async loadAsync(scrollToTop = true) {
            this.inLoading = true;
            if (scrollToTop)
                    window.scrollTo({ top: 0, behavior: 'smooth' });

            const result = await db.getAsync(this.filter.page, this.filter.perPage, this.filter.project_id, this.filter.customer_id, this.filter.startDate, this.filter.endDate, this.filter.is_private);

            if (result.success == true)
            {
                this.data = result.data.revenue.data;
                this.total = result.data.revenue.total;
                this.totalPage = result.data.revenue.last_page;
                this.totalPrice = result.data.total_price;
                this.paginationPageList = calcPaginationPageList(this.filter.page, this.totalPage);
            }

            this.inLoading = false;
        },

        async calcWageAsync(){

        },
        
        async bankCheckAsync(){
            NProgress.start();
            let ids = '';
            this.data.forEach(item => {
                if (item.is_bank_checked == 0)
                    ids += item.id + ',';
            });
 
            const result = await db.bankCheckAsync(ids);
            console.log(result);
            this.$toast.success(result);
            await this.loadAsync();
            
            NProgress.done();
        },

        async setBankCheckedStatusAsync(revenue){
            NProgress.start();
            let updateObj = {
                is_bank_checked: 1
            };
            const result = await db.updateAsync(revenue.id, updateObj);
            if (result.success == true){
                revenue.is_bank_checked = 1;
                this.$toast.success('Đã đối soát');
            } else {
                this.$toast.error(result.message);
            }
            NProgress.done();
        },
        
        // Filter ----------------------------------------------------------------------------------
        async resetFilterAsync(){
            this.filter.customer_id = '';
            this.filter.project_id = '';
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            const currentMonth = currentDate.getMonth()+1;
            const lastDay = this.getLastdayOfCurrentMonth();
            this.filter.startDate = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-01`;
            this.filter.endDate = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
            this.filter.page = 1;
  
            await this.loadAsync();
        },

        async updateCustomerIdFilterAsync(id){
            this.filter.customer_id = id;
            this.filter.page = 1;

            await this.loadAsync();
        },

        async updateProjectIdFilterAsync(id){
            this.filter.project_id = id;
            this.filter.page = 1;

            await this.loadAsync();
        },

        async filterByDateAsync(){
            this.filter.page = 1;

            await this.loadAsync();
        },

        async filterByTypeAsync(){
            this.filter.page = 1;

            await this.loadAsync();
        },

        // Pagination --------------------------------------------------------------------------------
        async nextPageAsync(){
            if (this.filter.page == this.totalPage)
                return;

            this.filter.page++;
            await this.loadAsync();
        },

        async prevPageAsync(){
            if (this.filter.page <= 1)
                return;

            this.filter.page--;
            await this.loadAsync();
        },

        async goToPageAsync(page){
            this.filter.page = page;
            await this.loadAsync();
        },

        // Loader --------------------------------------------------------------------------------------
        async loadProjectSelectBoxAsync(){
            const result = await getProjectsAsync(1, 100, '', 'name', 'asc', 'id,name');
            if (result.success == true)
                this.$refs.selectProject.updateSuggestList(result.data.data, 'name', 'id');

            if (this.filter.project_id){
                this.$refs.selectProject.setSelectedId(this.filter.project_id);
            }
        },

        // Helpers -------------------------------------------------------------------------------------
        formatDateStr(dateString){
            return formatDateTimeString(dateString);
        },
        formatVNPrice(price){
            return formatPrice(price * 1000);
        },
        getLastdayOfCurrentMonth(){
            const currentDate = new Date();
            const currentMonth = currentDate.getMonth();
            const nextMonth = currentMonth + 1;
            const nextMonthFirstDay = new Date(currentDate.getFullYear(), nextMonth, 1);
            const lastDayOfMonth = new Date(nextMonthFirstDay.getTime() - 1);
            const lastDay = lastDayOfMonth.getDate();

            return lastDay;
        }
    }
    
}
</script>
  
<style scoped>

.total {
    margin-bottom: 20px;
    padding: 5px 15px;
    border: 1px solid #0080c0;
    color: #0080c0;
    background: #dcf3ff;
    border-radius: 5px;
    display: block;
}

.badge {
    margin-left: 15px;
}
</style>