<template>
    <div>
        <div class="title-wrapper">
            <h1>Công cụ cho Admin</h1>
        </div>

        <div class="tool-container">
            <!-- License tools -->
            <div class="row">
                <div class="col-md-4">
                    <div class="tool-box" @click="deleteExpiredLicensesAsync">
                        <div class="tool-box__title">
                            <span><i class="far fa-trash-alt"></i> Xóa license dùng thử hết hạn</span>
                        </div>
                        <p class="tool-box__desc"><small>Xóa toàn bộ license dùng thử đã hết hạn, việc này giúp giảm nhẹ dung lượng database</small></p>
                        <div class="loading" v-show="status.onDeleteExpiredLicese">
                            <div class="spinner-border text-secondary spinner-border-sm" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        
    </div>
</template>
  
<script>
import {removeExpiredTrialLicensesAsync, convertDevicesFromOldSystemAsync} from '@/services/licenseService';
import {convertUserFromOldSystem} from '@/services/userService';
import {updateAsync as updateDbAsync, exportAsync as exportDbAsync} from '@/services/databaseService';
import { saveAs } from 'file-saver';

export default {
    name: 'ToolPage',
    components: {
    },
    created() {
        document.title = 'Admin tools';
    },
    data() {
        return {
            status: {
                onDeleteExpiredLicese: false,
                onRestartCronTab: false,
                onConvertUserDB: false,
                onConvertDevicesDB: false,
                onUpdateDatabase: false,
                onExportDatabase: false,

                convertUserDBStatusLabel: 'Sẵn sàng',
                convertDeviceDBStatusLabel: 'Sẵn sàng'
            }
        }
    },
    methods: {
        async deleteExpiredLicensesAsync() {
            if (confirm('Xác nhận xóa toàn bộ License dùng thử hết hạn?')) {
                this.status.onDeleteExpiredLicese = true;
                const result = await removeExpiredTrialLicensesAsync();
                this.status.onDeleteExpiredLicese = false;

                if (result.success == true) {
                    this.$toast.success(`Xóa thành công ${result.data} licenses`);
                }
            }
        },

        async convertUserDatabaseFromOldSystemAsync(){
            if (confirm('Đồng bộ dữ liệu khách hàng từ hệ thống cũ?')) {
                this.status.onConvertUserDB = true;

                // Sync user data ---------------------------------------------------------------------------
                let totalConverted = 0;
                let page = 1;
                
                // eslint-disable-next-line
                while (true){
                    this.status.convertUserDBStatusLabel = `Đang đồng bộ page ${page} (tổng ${totalConverted})`;
                    const result = await convertUserFromOldSystem(page);
                    if (result.success == true){
                        let converted =  parseInt(result.data);
                        if (converted == 0)
                            break;

                        totalConverted += converted;
                        page++;
                    } else {
                        this.status.convertUserDBStatusLabel = `Lỗi: ${result.message}. Chờ 10s để thử lại!`;
                        await this.sleep(10000);
                    }
                }

                this.status.convertUserDBStatusLabel = `Sẵn sàng`;
                this.status.onConvertUserDB = false;
                this.$toast.success(`Đồng bộ thành công ${totalConverted} người dùng`);
            }
        },

        async convertDeviceDatabaseFromOldSystemAsync(){
            const oldProjectID = prompt("Nhập project id từ hệ thống cũ", "");

            if (confirm('Đồng bộ dữ liệu device từ hệ thống cũ?')) {
                this.status.onConvertDevicesDB = true;

                // Sync user data ---------------------------------------------------------------------------
                let totalConverted = 0;
                let page = 1;
                
                // eslint-disable-next-line
                while (true){
                    this.status.convertDeviceDBStatusLabel = `Đang đồng bộ page ${page} (tổng ${totalConverted})`;
                    const result = await convertDevicesFromOldSystemAsync(oldProjectID, page);

                    if (result.success == true){
                        let converted =  parseInt(result.data);
                        if (converted == 0)
                            break;

                        totalConverted += converted;
                        page++;
                    } else {
                        this.status.convertDeviceDBStatusLabel = `Lỗi: ${result.message}. Chờ 10s để thử lại!`;
                        await this.sleep(10000);
                    }
                }

                this.status.convertDeviceDBStatusLabel = `Sẵn sàng`;
                this.status.onConvertDevicesDB = false;
                this.$toast.success(`Đồng bộ thành công devices cho ${totalConverted} license`);
            }
        },

        async updateDBStructureAsync() {
            if (confirm('Xác nhận update database?')) {
                this.status.onUpdateDatabase = true;
                const result = await updateDbAsync();
                this.status.onUpdateDatabase = false;

                if (result.success == true) {
                    this.$toast.success(`Cập nhật cấu trúc thành công`);
                } else {
                    this.$toast.error(`Database đã được update lên cấu trúc mới nhất`);
                }
            }
        },

        async exportDatabaseAsync() {
            this.status.onExportDatabase = true;
            const result = await exportDbAsync();
            this.status.onExportDatabase = false;

            if (result.success == true) {
                var blob = new Blob([JSON.stringify(result.data)], {type: "text/plain;charset=utf-8"});
                saveAs(blob, "DatabaseExport.json");
            } else {
                this.$toast.error(`Xuất JSON thất bại, vui lòng thử lại`);
            }
        },

        // Helpers -----------------------------------------------------
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
    }
    
}
</script>

<style scoped>
.tool-box
{
    padding: 20px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background: white;
    cursor: pointer;
    margin-bottom: 20px;
    position: relative;
}

.tool-box:hover {
    background: #d7ebff;
}

.tool-box__title span {
    font-weight: bold;
}

.tool-box__title span i {
    margin-right: 10px;
}

.tool-box__desc {
    margin-top: 10px;
}

.tool-box .loading {
    position: absolute;
    top: 15px;
    right: 15px;
}

hr {
    color: rgb(173, 173, 173);
}
</style>