import { httpGetAsync } from "./requestService";

/**
 * Update database structure
 */
export const updateAsync = async () => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/database/update`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

/**
 * Export database
 */
export const exportAsync = async () => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/database/export`;
    const resp = await httpGetAsync(url);

    return resp.data;
}


